import { ListItemText, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { components } from "../../../api/schema";
import FormHourSelect from "../../../components/formComponents/FormHourSelect";
import SingleSelect from "../../../components/SingleSelect";
import DeleteIcon from "../../../Icons/DeleteIcon";
import { handleDisplayFromTime } from "../../../utils/dateAndTimeUtils";
import SelectDays from "./SelectDays";

enum TimeRange {
  AllDay = "All day",
  CustomTime = "Custom",
}
interface Props {
  index: number;
  namePrefix: string;
  ruleIndex: number;
  periodIndex: number;
  remove: <T>(index: number) => T | undefined;
  numberOfPeriods: number;
  isCustomizedPolicy: boolean;
}

const Period = ({ index, namePrefix, ruleIndex, periodIndex, remove, numberOfPeriods, isCustomizedPolicy }: Props) => {
  const [timeRangeHasChangedByUser, setTimeRangeHasChangedByUser] = useState(false);
  const [timeRange, setTimeRange] = useState<TimeRange>(TimeRange.AllDay);
  const isDeleteEnabled = isCustomizedPolicy && numberOfPeriods > 1;

  const { values, setFieldValue } = useFormikContext<{
    rules?: components["schemas"]["V1alpha1SchedulePolicyRule"][];
  }>();

  useEffect(() => {
    if (!values.rules?.[ruleIndex]?.periods?.[periodIndex]?.weeklyConfig?.days) {
      setFieldValue(`${namePrefix}.weeklyConfig.days`, [0, 1, 2, 3, 4, 5, 6]);
    }

    if (
      values.rules?.[ruleIndex]?.periods?.[periodIndex]?.weeklyConfig?.beginTime !== "00:00" ||
      values.rules?.[ruleIndex]?.periods?.[periodIndex]?.weeklyConfig?.endTime !== "00:00"
    ) {
      setTimeRange(TimeRange.CustomTime);
    }
  }, []);

  useEffect(() => {
    if (timeRange === TimeRange.AllDay && timeRangeHasChangedByUser) {
      setFieldValue(`${namePrefix}.weeklyConfig.beginTime`, "00:00");
      setFieldValue(`${namePrefix}.weeklyConfig.endTime`, "00:00");
    }
  }, [timeRange, timeRangeHasChangedByUser]);

  const fromValue = values.rules?.[ruleIndex]?.periods?.[periodIndex]?.weeklyConfig?.beginTime;
  const fromValuePlusOneHour = fromValue
    ? `${String((Number(fromValue.split(":")[0]) + 1) % 24).padStart(2, "0")}:${fromValue.split(":")[1]}`
    : undefined;

  const disableFromAndTo = timeRange === TimeRange.AllDay;
  return (
    <div className="bg-white p-5 border border-border rounded-lg relative flex justify-center gap-2">
      <SelectDays name={`${namePrefix}.weeklyConfig.days`} />
      <div className="flex flex-col gap-1">
        <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
          Time range
        </Typography>
        <SingleSelect<TimeRange>
          selected={timeRange}
          setSelected={(value) => {
            setTimeRangeHasChangedByUser(true);
            setTimeRange(value);
          }}
          renderOptionsFunc={() =>
            Object.entries(TimeRange).map((option) => (
              <MenuItem value={option[1]} key={option[1]}>
                <ListItemText primary={option[1]} />
              </MenuItem>
            ))
          }
          className="w-[125px] h-[34px]"
        />
      </div>
      <FormHourSelect
        name={`${namePrefix}.weeklyConfig.beginTime`}
        label="From"
        className="w-[192px]"
        disabled={disableFromAndTo}
      />
      <FormHourSelect
        name={`${namePrefix}.weeklyConfig.endTime`}
        label="To"
        className="w-[192px]"
        hourArrayStartTime={fromValuePlusOneHour}
        showNetDayIndicator
        disabled={disableFromAndTo}
        formatDisplayValue={(value) => {
          const timeToDisplay = handleDisplayFromTime(value);
          return timeToDisplay;
        }}
      />
      <DeleteIcon
        type="button"
        onClick={() => {
          if (isDeleteEnabled) remove(index);
        }}
        width={14}
        height={14}
        className={clsx("absolute top-1.5 right-1.5", {
          "cursor-not-allowed opacity-20": !isDeleteEnabled,
          "hover:opacity-80 cursor-pointer": isDeleteEnabled,
        })}
      />
    </div>
  );
};

export default Period;
