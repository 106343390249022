import { Typography } from "@mui/material";
import clsx from "clsx";
import Tooltip from "../../../components/Tooltip";
import { LABEL_BOX_CLASS_WRAPPER, METRIC_BOX_CLASS_NAME } from "./utils";
import InfoIcon from "../../../Icons/InfoIcon";

interface Props {
  strategyName: string;
  strategyType: string;
  maxSurge?: number | null;
  maxUnavailable?: number | null;
  cpuOverridden?: boolean;
  memoryOverridden?: boolean;
  className?: string;
  children?: React.ReactNode;
  isAuto: boolean | undefined;
  autoIndication: string | undefined;
}

const autoIndicationMessages: Record<string, string> = {
  "UI-Workload": "This workload is automated by workload action (UI).",
  "UI-Namespace": "This workload is automated by namespace action (UI).",
  "UI-Cluster": "This workload is automated by cluster action (UI).",
  "CM-Cluster": "This workload is automated by `scaleops-cluster-automation` config map.",
  "Annotation-default-namespace": "This workload is automated by `scaleops.sh/default-auto`namespace annotation.",
  "Annotation-default-workload": "This workload is automated by `scaleops.sh/default-auto` workload annotation.",
  "ANS-Namespace": "This workload is automated by AutomatedNamespace CR.",
  "Annotation-force-Workload": "This workload is automated by `scaleops.sh/force-auto` workload annotation.",
  "COG-default-auto": "This workload is automated by CustomOwnerGrouping CR `defaultAuto` definition.",
  "Annotation-custom-label": "This workload is automated by custom namespace label (defined in the settings page).",
};

const AdditionalInfoBadge = ({
  strategyName,
  strategyType,
  maxSurge,
  maxUnavailable,
  cpuOverridden,
  memoryOverridden,
  className,
  children,
  isAuto,
  autoIndication,
}: Props) => {
  if (!strategyName || !strategyType) {
    return null;
  }
  const formatStringWithoutBackticks = (str: string): JSX.Element => {
    const parts = str.split(/(`[^`]*`)/); // Split on backticks and include them in the result
    return (
      <>
        {parts.map((part, index) =>
          part.startsWith("`") && part.endsWith("`") ? (
            <i key={index}>{part.slice(1, -1)}</i> // Remove backticks and render content in italics
          ) : (
            <span key={index}>{part}</span> // Render other text normally
          )
        )}
      </>
    );
  };

  return (
    <Tooltip
      title={
        <>
          <Typography variant="caption">
            <b>{strategyType}</b>
            {maxSurge != undefined && <li>{`maxSurge: ${maxSurge}`}</li>}
            {maxUnavailable != undefined && <li>{`maxUnavailable: ${maxUnavailable}`}</li>}
          </Typography>
          <br />
          {cpuOverridden || memoryOverridden ? (
            <Typography variant="caption">
              <b>Recommendation overridden</b>
              {cpuOverridden && <li>{`cpu`}</li>}
              {memoryOverridden && <li>{`memory`}</li>}
            </Typography>
          ) : (
            <></>
          )}
          {isAuto && autoIndication && (
            <Typography variant="caption">
              <b>Auto Indication</b>
              <li>{formatStringWithoutBackticks(autoIndicationMessages[autoIndication])}</li>
            </Typography>
          )}
        </>
      }
      disabled={maxSurge === undefined && maxUnavailable === undefined && !cpuOverridden && !memoryOverridden}
      placement="left"
      maxWidth={400}
    >
      <div className={clsx(METRIC_BOX_CLASS_NAME, LABEL_BOX_CLASS_WRAPPER, className)}>
        Additional info <InfoIcon width={14} height={14} /> {children}
      </div>
    </Tooltip>
  );
};

export default AdditionalInfoBadge;
