import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Checkbox, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { FixedSizeList as List } from "react-window";
import { LogicalOperator } from "../api/fetcher";
import AddIcon from "../Icons/AddIcon";
import { camelCaseToSnakeSpace, capitalizeFirstLetter } from "../utils/formatterUtils";
import Tooltip from "./Tooltip";
import useSelectSearch, { CustomSelectOptions } from "./useSelectSearch";

type CategoryOptions = {
  categoryName: string;
  options: string[];
};

const MULTISELECT_MENU_CATEGORY_ID = "multiselect-menu-category-id";

const CategoryHr = () => <hr className="border-border grow" />;

const MAX_LIST_HEIGHT = 300;
const LIST_SIZE = 54;

const SELECT_BUTTON_CLASS_NAME = "hover:bg-guideline-lightGray border border-border rounded px-2 cursor-pointer";

const defaultSortFunction = (a: string, b: string, sort?: "asc" | "desc") => {
  if (sort === "asc") {
    return a.localeCompare(b);
  }
  if (sort === "desc") {
    return b.localeCompare(a);
  }
  return 0;
};

const numberOfSelectedRenderValueFnc = (selected: (string | undefined)[]) => `Selected (${selected.length ?? 0})`;

const defaultRenderValue = (selected: (string | undefined)[]) => {
  if (selected.length === 0) {
    return "Selected (0)";
  }
  return `${selected?.join(", ")}`;
};

const defaultOptionRenderFunction = (
  option: string,
  index: number,
  selected: (string | undefined)[],
  dataTestId?: string,
  capitalize?: boolean,
  modifyOptionText?: (option: string) => string
) => {
  let optionText = modifyOptionText ? modifyOptionText(option) : option;
  optionText = capitalize ? capitalizeFirstLetter(optionText) : optionText;

  if (option.includes(MULTISELECT_MENU_CATEGORY_ID))
    return (
      <div className="w-full relative flex items-center justify-center px-2 pt-4">
        <CategoryHr />
        <Typography variant="caption" className="select-none text-text-lightBlack bg-white px-4">
          {option.replaceAll(MULTISELECT_MENU_CATEGORY_ID, "")}
        </Typography>
        <CategoryHr />
      </div>
    );

  return (
    <MenuItem
      value={option}
      key={`${index}-multi-select-option`}
      data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
      className="w-full"
    >
      <div className="min-w-[235px] flex items-center pr-[10px]">
        <Checkbox checked={selected.indexOf(option) > -1} />
        <Tooltip title={optionText} className="w-full truncate" maxWidth={500}>
          <ListItemText
            primary={optionText}
            primaryTypographyProps={{
              sx: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "calc(100%)",
              },
            }}
            sx={{
              width: "100%",
            }}
          />
        </Tooltip>
      </div>
    </MenuItem>
  );
};

export const camelCaseToSnakeSpaceOptionRenderFunction = (
  option: string,
  index: number,
  selected: (string | undefined)[],
  dataTestId?: string
) => (
  <MenuItem
    value={option}
    key={`${index}-multi-select-option`}
    data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
  >
    <Checkbox checked={selected.indexOf(option) > -1} />
    <ListItemText primary={camelCaseToSnakeSpace(option)} />
  </MenuItem>
);

interface MultiSelectProps {
  label?: React.ReactNode;
  selected: (string | undefined)[];
  setSelected: (props: (string | undefined)[]) => void;
  options?: string[];
  categoryOptions?: CategoryOptions[];
  className?: string;
  labelClassName?: string;
  listHeight?: string;
  listWidth?: string;
  optionRenderFunction?: (
    option: string,
    index: number,
    selected: (string | undefined)[],
    dataTestId?: string,
    capitalize?: boolean,
    modifyOptionText?: (option: string) => string
  ) => JSX.Element;
  isSearchable?: boolean;
  sort?: "asc" | "desc";
  customIcon?: JSX.Element;
  customInput?: React.ReactNode;
  disabled?: boolean;
  isExclude?: boolean | null | undefined;
  setIsExclude?: (value: boolean | null | undefined) => void;
  logicalOperator?: LogicalOperator;
  setLogicalOperator?: (value: LogicalOperator) => void;
  dataTestId?: string;
  width?: number | undefined;
  capitalize?: boolean;
  tooltipPrefix?: React.ReactNode;
  hasSelectAllAndClearSelection?: boolean;
  hasVirtualizedList?: boolean;
  fontSize?: string;
  wrapperClassName?: string;
  renderValue?: (selected: (string | undefined)[]) => string;
  showNumberOfSelectedValue?: boolean;
  customOnOpen?: () => void;
  customOnClose?: () => void;
  addCustomValueFnc?: (searchTerm: string) => void;
  disableTooltip?: boolean;
  customSelectOptions?: CustomSelectOptions;
  modifyOptionText?: (option: string) => string;
  openOnInitialRender?: boolean;
  sortFnc?: (a: string, b: string, sort?: "asc" | "desc") => number;
}

const MultiSelect = ({
  selected,
  setSelected,
  options,
  categoryOptions,
  label,
  className,
  labelClassName,
  listHeight,
  listWidth,
  optionRenderFunction = defaultOptionRenderFunction,
  isSearchable,
  sort,
  customIcon,
  customInput,
  disabled,
  isExclude,
  setIsExclude,
  logicalOperator,
  setLogicalOperator,
  dataTestId,
  width,
  capitalize,
  tooltipPrefix,
  hasSelectAllAndClearSelection = true,
  hasVirtualizedList,
  fontSize,
  wrapperClassName,
  renderValue,
  showNumberOfSelectedValue,
  customOnOpen,
  customOnClose,
  addCustomValueFnc,
  disableTooltip,
  customSelectOptions,
  modifyOptionText,
  openOnInitialRender,
  sortFnc = defaultSortFunction,
}: MultiSelectProps) => {
  const optionsList =
    options ??
    categoryOptions?.flatMap((category) => [
      `${MULTISELECT_MENU_CATEGORY_ID}${category.categoryName}`,
      ...category.options,
    ]) ??
    [];

  const { search, searchDiv, includeExcludeDiv, logicalOperatorDiv, customOptionDiv } = useSelectSearch(
    isSearchable,
    isExclude,
    setIsExclude,
    undefined,
    logicalOperator,
    setLogicalOperator,
    setSelected,
    customSelectOptions
  );
  const [isOpen, setIsOpen] = useState<boolean>(!!openOnInitialRender);

  switch (true) {
    case !!renderValue:
      break;
    case showNumberOfSelectedValue:
      renderValue = numberOfSelectedRenderValueFnc;
      break;
    default:
      renderValue = defaultRenderValue;
  }

  const filteredOptions = optionsList
    .filter((entity) => entity !== undefined)
    .filter((entity) => {
      if (!isSearchable || entity.includes(MULTISELECT_MENU_CATEGORY_ID)) return true;
      const entityDisplayValue = modifyOptionText ? modifyOptionText(entity) : entity;
      return entityDisplayValue.toLocaleLowerCase()?.includes(search.toLowerCase()) || false;
    })
    .sort((a, b) => sortFnc(a, b, sort));

  const LIST_HEIGHT = Math.min(MAX_LIST_HEIGHT, LIST_SIZE * filteredOptions.length);

  const hasNoResults = !filteredOptions?.length && search.length > 0;

  return (
    <div className={clsx(wrapperClassName, "flex flex-col gap-1 relative")}>
      {label && <Typography className={clsx(labelClassName, "text-text-lightBlack")}>{label}</Typography>}
      {customIcon && (
        <div className={clsx(className, "absolute w-48 flex justify-center items-center")} style={{ width: width }}>
          {customIcon}
        </div>
      )}
      <Tooltip
        title={
          <div className="max-h-[50vh] overflow-auto">
            {tooltipPrefix ?? ""}
            {selected?.map((value) => (modifyOptionText ? modifyOptionText(value ?? "") : value)).join(", ")}.
          </div>
        }
        disabled={isOpen || selected?.length === 0 || disableTooltip}
      >
        <Select
          data-testid={dataTestId}
          disabled={disabled}
          multiple
          value={selected}
          displayEmpty
          renderValue={renderValue}
          onChange={(event) => {
            setSelected(event.target.value as (string | undefined)[]);
          }}
          className={clsx(className, "h-8 w-48")}
          IconComponent={(props) => <KeyboardArrowDownIcon className="mr-2" {...props} />}
          sx={{
            opacity: customIcon ? 0 : 1,
            width: width,
            fontSize: fontSize,
          }}
          MenuProps={{
            autoFocus: false,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            classes: {
              paper: "bg-white",
            },
            style: {
              opacity: 1,
              maxHeight: listHeight,
              maxWidth: listWidth,
              marginTop: 10,
            },
          }}
          onOpen={() => {
            customOnOpen && customOnOpen();
            setIsOpen(true);
          }}
          onClose={() => {
            customOnClose && customOnClose();
            setIsOpen(false);
          }}
          open={isOpen}
        >
          {isSearchable && searchDiv}
          {customInput}
          <div className="flex gap-4">
            {setIsExclude && includeExcludeDiv}
            {setIsExclude && setLogicalOperator && (
              <div className="flex items-end mt-[12px] w-[1px] h-[14px] bg-text-disable" />
            )}
            {setLogicalOperator && logicalOperatorDiv}
            {setIsExclude && setLogicalOperator && customOptionDiv && (
              <div className="flex items-end mt-[12px] w-[1px] h-[14px] bg-text-disable" />
            )}
            {customOptionDiv}
          </div>
          <div className="flex items-center px-[25px] mt-[10px]">
            {hasSelectAllAndClearSelection && (
              <p className="text-[12px] flex justify-start align-middle pb-1 gap-2">
                <span
                  onClick={() => {
                    setSelected([...selected, ...filteredOptions.filter((option) => !selected.includes(option))]);
                  }}
                  className={SELECT_BUTTON_CLASS_NAME}
                >
                  select all
                </span>
                <span
                  onClick={() => {
                    setSelected([]);
                  }}
                  className={SELECT_BUTTON_CLASS_NAME}
                >
                  clear selection
                </span>
              </p>
            )}
            {addCustomValueFnc && (
              <p className="text-[12px] flex justify-end items-center grow">
                <span
                  onClick={() => hasNoResults && addCustomValueFnc(search)}
                  className={clsx(
                    {
                      "opacity-50 cursor-not-allowed": !hasNoResults,
                    },
                    SELECT_BUTTON_CLASS_NAME,
                    "flex gap-1 items-center"
                  )}
                >
                  <AddIcon width={12} height={12} />
                  Add custom value
                </span>
              </p>
            )}
          </div>
          {hasVirtualizedList ? (
            <List height={LIST_HEIGHT} itemCount={filteredOptions?.length ?? 0} itemSize={LIST_SIZE} width={500}>
              {({ index, style }) => (
                <div
                  style={style}
                  onClick={() => {
                    if (selected.includes(filteredOptions[index])) {
                      setSelected(selected.filter((option) => option !== filteredOptions[index]));
                    } else {
                      setSelected([...selected, filteredOptions[index]]);
                    }
                  }}
                >
                  {optionRenderFunction(
                    filteredOptions[index],
                    index,
                    selected,
                    dataTestId,
                    capitalize,
                    modifyOptionText
                  )}
                </div>
              )}
            </List>
          ) : (
            filteredOptions.map((option, index) =>
              optionRenderFunction(option, index, selected, dataTestId, capitalize, modifyOptionText)
            )
          )}
        </Select>
      </Tooltip>
    </div>
  );
};

export default MultiSelect;
