import prettyBytes from "pretty-bytes";
import { Elements } from "../../../components/GridLayout/utils";
import { camelCaseToSnakeSpace, capitalizeFirstLetter } from "../../../utils/formatterUtils";
import { TooltipTrigger, UpdateActiveTooltips } from "../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import AutomatedDowntimeGraph from "./AutomatedDowntimeGraph";
import AutomationEventsGraph from "./AutomationSummaryGraph";
import ClusterDisruptionsGraph from "./ClusterDisruptionsGraph";
import CPURequestsGraph from "./CPURequestsGraph";
import CPUThrottlingEventsGraph from "./CPUThrottlingEventsGraph";
import CPUUnderProvisionedGraph from "./CPUUnderProvisionedGraph";
import CPUUnderProvisionedOnStressedNodesGraph from "./CPUUnderProvisionedOnStressedNodesGraph";
import CPUWastedDueToUnsuggestedPoliciesGraph from "./CPUWastedDueToUnsuggestedPoliciesGraph";
import CustomWorkloadsUnidentifiedGraph from "./CustomWorkloadsUnidentifiedGraph";
import DowntimeEventsGraph from "./DowntimeEventsGraph";
import DowntimeGraph from "./DowntimeGraph";
import ExpensiveGraph from "./ExpensiveGraph";
import IssuesGraph from "./IssuesGraph";
import LivenessProbeFailuresGraph from "./LivenessProbeFailuresGraph";
import MemoryRequestsGraph from "./MemoryRequestsGraph";
import MemoryUnderProvisionedGraph from "./MemoryUnderProvisionedGraph";
import MemoryUnderProvisionedOnStressedNodes from "./MemoryUnderProvisionedOnStressedNodes";
import MemoryUsageGraph from "./MemoryUsageGraph";
import MemoryWastedDueToUnsuggestedPoliciesGraph from "./MemoryWastedDueToUnsuggestedPoliciesGraph";
import NamespaceLimitationByCPUGraph from "./NamespaceLimitationByCPUGraph";
import NamespaceLimitationByMemoryGraph from "./NamespaceLimitationByMemoryGraph";
import NamespaceLimitationByPodsGraph from "./NamespaceLimitationByPodsGraph";
import NamespaceLimitationByReplicaSetsGraph from "./NamespaceLimitationByReplicaSetsGraph";
import NodeCPUAllocationGraph from "./NodeCPUAllocationGraph";
import NodeCpuUtilizationGraph from "./NodeCpuUtilizationGraph";
import NodeInstanceTypeGraph from "./NodeInstanceTypeGraph";
import NodeLifeCycleGraph from "./NodeLifeCycleGraph";
import NodeMemoryAllocationGraph from "./NodeMemoryAllocationGraph";
import NodeMemoryUtilizationGraph from "./NodeMemoryUtilizationGraph";
import NodeNotScalingDownGraph from "./NodeNotScalingDownGraph";
import OOMLimitGraph from "./OOMlimitGraph";
import OOMNodeGraph from "./OOMnodeGraph";
import OutOfMemoryEventsGraph from "./OutOfMemoryEventsGraph";
import ScaleOpsCPURequests from "./ScaleOpsCPURequests";
import ScaleOpsCPUUsage from "./ScaleOpsCPUUsage";
import ScaleOpsMemoryRequests from "./ScaleOpsMemoryRequests";
import ScaleOpsMemoryUsage from "./ScaleOpsMemoryUsage";
import ScaleOpsOptimizationEvictionsGraph from "./ScaleOpsOptimizationEvictionsGraph";
import SmartPolicyWasteGraph from "./SmartPolicyWasteGraph";
import UserAutomationEventsGraph from "./UserAutomationEventsGraph";
import UserUnAutomationEventsGraph from "./UserUnAutomationEventsGraph";
import VersionGraph from "./VersionGraph";
import WastedCPUGraph from "./WastedCPUGraph";
import WastedMemoryGraph from "./WastedMemoryGraph";
import WastefulGraph from "./WastefulGraph";
import WorkloadDisruptionsGraph from "./WorkloadDisruptionsGraph";

export const SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS = 20;
export const HAS_TOPK_FILTERS = true;
export const ELEMENT_CLASSNAME = "w-[calc(50%-20px)]";

export const PERCENTAGE_Y_AXIS_DOMAIN = [0, 100];
export const RESOURCE_QUOTA = <span className="text-text-darkGray">(resource quota)</span>;
export const GENERAL_SYNC_ID = "GENERAL_SYNC_ID";
export const NODE_SYNC_ID = "NODE_SYNC_ID";
export const RESOURCE_QUOTA_SYNC_ID = "RESOURCE_QUOTA_SYNC_ID";

export const memoryFormatter = (value: string | number) => {
  value = Number(value) * 1024 * 1024;
  if (value === Infinity || value === -Infinity || Number.isNaN(Number(value))) {
    value = 0;
  }

  return prettyBytes(value, {
    bits: false,
    binary: true,
  });
};

export const getLegendComponentName = (name: string | undefined, isMultiCluster = false) => {
  if (!name) return "";
  const splitName = name.split("/");
  const numberOfElements = splitName.length;
  const lastElement = splitName[numberOfElements - 1];

  return isMultiCluster ? `${splitName[0]}/${lastElement}` : lastElement;
};

export const capitalizeFirstLetterAndCamelCaseToSnakeSpace = (name: string | undefined) =>
  name ? capitalizeFirstLetter(camelCaseToSnakeSpace(name)) : "";

export const legendName = (name: string | undefined) => getLegendComponentName(name);

export const multiClusterLegendName = (name: string | undefined) => getLegendComponentName(name, true);

export interface CommonGraphProps {
  isMulticluster?: boolean;
  tooltipTrigger?: TooltipTrigger;
  disabledZoom?: boolean;
  updateActiveTooltips: UpdateActiveTooltips | undefined;
}

export enum Graph {
  AutomationEventsGraph = "AutomationEventsGraph",
  OutOfMemoryEventsGraph = "OutOfMemoryEventsGraph",
  WorkloadDisruptionsGraph = "WorkloadDisruptionsGraph",
  OOMNodeGraph = "OOMNodeGraph",
  DowntimeEventsGraph = "DowntimeEventsGraph",
  LivenessProbeFailuresGraph = "LivenessProbeFailuresGraph",
  CPUThrottlingEventsGraph = "CPUThrottlingEventsGraph",
  CPUUnderProvisionedOnStressedNodesGraph = "CPUUnderProvisionedOnStressedNodesGraph",
  MemoryUnderProvisionedOnStressedNodesGraph = "MemoryUnderProvisionedOnStressedNodesGraph",
  OOMLimitGraph = "OOMLimitGraph",
  UserAutomationEventsGraph = "UserAutomationEventsGraph",
  UserUnAutomationEventsGraph = "UserUnAutomationEventsGraph",
  ExpensiveGraph = "ExpensiveGraph",
  WastefulGraph = "WastefulGraph",
  WastedCPUGraph = "WastedCPUGraph",
  WastedMemoryGraph = "WastedMemoryGraph",
  SmartPolicyWasteGraph = "SmartPolicyWasteGraph",
  CustomWorkloadsUnidentifiedGraph = "CustomWorkloadsUnidentifiedGraph",
  CPUWastedDueToUnsuggestedPoliciesGraph = "CPUWastedDueToUnsuggestedPoliciesGraph",
  MemoryWastedDueToUnsuggestedPoliciesGraph = "MemoryWastedDueToUnsuggestedPoliciesGraph",
  DowntimeGraph = "DowntimeGraph",
  AutomatedDowntimeGraph = "AutomatedDowntimeGraph",
  CPUUnderProvisionedGraph = "CPUUnderProvisionedGraph",
  MemoryUnderProvisionedGraph = "MemoryUnderProvisionedGraph",
  ScaleOpsOptimizationEvictionsGraph = "ScaleOpsOptimizationEvictionsGraph",
  ClusterDisruptionsGraph = "ClusterDisruptionsGraph",
  MemoryUsageGraph = "MemoryUsageGraph",
  CPURequestsGraph = "CPURequestsGraph",
  MemoryRequestsGraph = "MemoryRequestsGraph",
  IssuesGraph = "IssuesGraph",
  VersionGraph = "VersionGraph",
  NodeCpuUtilizationGraph = "NodeCpuUtilizationGraph",
  NodeMemoryUtilizationGraph = "NodeMemoryUtilizationGraph",
  NodeCPUAllocationGraph = "NodeCPUAllocationGraph",
  NodeMemoryAllocationGraph = "NodeMemoryAllocationGraph",
  NodeNotScalingDownGraph = "NodeNotScalingDownGraph",
  NodeInstanceTypeGraph = "NodeInstanceTypeGraph",
  NodeLifeCycleGraph = "NodeLifeCycleGraph",
  NamespaceLimitationByMemoryGraph = "NamespaceLimitationByMemoryGraph",
  NamespaceLimitationByCPUGraph = "NamespaceLimitationByCPUGraph",
  NamespaceLimitationByPodsGraph = "NamespaceLimitationByPodsGraph",
  NamespaceLimitationByReplicaSetsGraph = "NamespaceLimitationByReplicaSetsGraph",
  ScaleOpsCPUUsage = "ScaleOpsCPUUsage",
  ScaleOpsMemoryUsage = "ScaleOpsMemoryUsage",
  ScaleOpsCPURequests = "ScaleOpsCPURequests",
  ScaleOpsMemoryRequests = "ScaleOpsMemoryRequests",
}

export enum GraphDisplayName {
  AutomationEventsGraph = "Automation Events",
  OutOfMemoryEventsGraph = "Out of Memory Events",
  WorkloadDisruptionsGraph = "Workload Disruptions",
  OOMNodeGraph = "OOM Node",
  DowntimeEventsGraph = "Downtime Events",
  LivenessProbeFailuresGraph = "Liveness Probe Failures",
  CPUThrottlingEventsGraph = "CPU Throttling Events",
  CPUUnderProvisionedOnStressedNodesGraph = "CPU Under Provisioned On Stressed Nodes",
  MemoryUnderProvisionedOnStressedNodesGraph = "Memory Under Provisioned On Stressed Nodes",
  OOMLimitGraph = "OOM Limit",
  UserAutomationEventsGraph = "User Automation Events",
  UserUnAutomationEventsGraph = "User UnAutomation Events",
  ExpensiveGraph = "Expensive",
  WastefulGraph = "Wasteful",
  WastedCPUGraph = "Wasted CPU",
  WastedMemoryGraph = "Wasted Memory",
  SmartPolicyWasteGraph = "Smart Policy Waste",
  CustomWorkloadsUnidentifiedGraph = "Custom Workloads Unidentified",
  CPUWastedDueToUnsuggestedPoliciesGraph = "CPU Wasted Due to Unsuggested Policies",
  MemoryWastedDueToUnsuggestedPoliciesGraph = "Memory Wasted Due to Unsuggested Policies",
  DowntimeGraph = "Downtime",
  AutomatedDowntimeGraph = "Automated Downtime",
  CPUUnderProvisionedGraph = "CPU Under Provisioned",
  MemoryUnderProvisionedGraph = "Memory Under Provisioned",
  ScaleOpsOptimizationEvictionsGraph = "ScaleOps Optimization Evictions",
  ClusterDisruptionsGraph = "Cluster Disruptions",
  MemoryUsageGraph = "Memory Usage",
  CPURequestsGraph = "CPU Requests",
  MemoryRequestsGraph = "Memory Requests",
  IssuesGraph = "Workloads Issues",
  VersionGraph = "Version",
  NodeCpuUtilizationGraph = "Node CPU Utilization",
  NodeMemoryUtilizationGraph = "Node Memory Utilization",
  NodeCPUAllocationGraph = "Node CPU Allocation",
  NodeMemoryAllocationGraph = "Node Memory Allocation",
  NodeNotScalingDownGraph = "Node Not Scaling Down Reason",
  NodeInstanceTypeGraph = "Node Instance Type",
  NodeLifeCycleGraph = "Node Life Cycle",
  NamespaceLimitationByMemoryGraph = "Namespace Limitation By Memory",
  NamespaceLimitationByCPUGraph = "Namespace Limitation By CPU",
  NamespaceLimitationByPodsGraph = "Namespace Limitation By Pods",
  NamespaceLimitationByReplicaSetsGraph = "Namespace Limitation By Replica Sets",
  ScaleOpsCPUUsage = "ScaleOps CPU Usage",
  ScaleOpsMemoryUsage = "ScaleOps Memory Usage",
  ScaleOpsCPURequests = "ScaleOps CPU Requests",
  ScaleOpsMemoryRequests = "ScaleOps Memory Requests",
}

export const getGraphElements = (params: CommonGraphProps) => {
  const graphElements: Elements = {
    [Graph.AutomationEventsGraph]: <AutomationEventsGraph {...params} />,
    [Graph.OutOfMemoryEventsGraph]: <OutOfMemoryEventsGraph {...params} />,
    [Graph.WorkloadDisruptionsGraph]: <WorkloadDisruptionsGraph {...params} />,
    [Graph.OOMLimitGraph]: <OOMLimitGraph {...params} />,
    [Graph.OOMNodeGraph]: <OOMNodeGraph {...params} />,
    [Graph.DowntimeEventsGraph]: <DowntimeEventsGraph {...params} />,
    [Graph.LivenessProbeFailuresGraph]: <LivenessProbeFailuresGraph {...params} />,
    [Graph.CPUThrottlingEventsGraph]: <CPUThrottlingEventsGraph {...params} />,
    [Graph.CPUUnderProvisionedOnStressedNodesGraph]: <CPUUnderProvisionedOnStressedNodesGraph {...params} />,
    [Graph.MemoryUnderProvisionedOnStressedNodesGraph]: <MemoryUnderProvisionedOnStressedNodes {...params} />,
    [Graph.UserAutomationEventsGraph]: <UserAutomationEventsGraph {...params} />,
    [Graph.UserUnAutomationEventsGraph]: <UserUnAutomationEventsGraph {...params} />,
    [Graph.ExpensiveGraph]: <ExpensiveGraph {...params} />,
    [Graph.WastefulGraph]: <WastefulGraph {...params} />,
    [Graph.WastedCPUGraph]: <WastedCPUGraph {...params} />,
    [Graph.WastedMemoryGraph]: <WastedMemoryGraph {...params} />,
    [Graph.SmartPolicyWasteGraph]: <SmartPolicyWasteGraph {...params} />,
    [Graph.CustomWorkloadsUnidentifiedGraph]: <CustomWorkloadsUnidentifiedGraph {...params} />,
    [Graph.CPUWastedDueToUnsuggestedPoliciesGraph]: <CPUWastedDueToUnsuggestedPoliciesGraph {...params} />,
    [Graph.MemoryWastedDueToUnsuggestedPoliciesGraph]: <MemoryWastedDueToUnsuggestedPoliciesGraph {...params} />,
    [Graph.DowntimeGraph]: <DowntimeGraph {...params} />,
    [Graph.AutomatedDowntimeGraph]: <AutomatedDowntimeGraph {...params} />,
    [Graph.CPUUnderProvisionedGraph]: <CPUUnderProvisionedGraph {...params} />,
    [Graph.MemoryUnderProvisionedGraph]: <MemoryUnderProvisionedGraph {...params} />,
    [Graph.ScaleOpsOptimizationEvictionsGraph]: <ScaleOpsOptimizationEvictionsGraph {...params} />,
    [Graph.ClusterDisruptionsGraph]: <ClusterDisruptionsGraph {...params} />,
    [Graph.MemoryUsageGraph]: <MemoryUsageGraph {...params} />,
    [Graph.CPURequestsGraph]: <CPURequestsGraph {...params} />,
    [Graph.MemoryRequestsGraph]: <MemoryRequestsGraph {...params} />,
    [Graph.IssuesGraph]: <IssuesGraph {...params} />,
    [Graph.VersionGraph]: <VersionGraph {...params} />,
    [Graph.NodeCpuUtilizationGraph]: <NodeCpuUtilizationGraph {...params} />,
    [Graph.NodeMemoryUtilizationGraph]: <NodeMemoryUtilizationGraph {...params} />,
    [Graph.NodeCPUAllocationGraph]: <NodeCPUAllocationGraph {...params} />,
    [Graph.NodeMemoryAllocationGraph]: <NodeMemoryAllocationGraph {...params} />,
    [Graph.NodeNotScalingDownGraph]: <NodeNotScalingDownGraph {...params} />,
    [Graph.NodeInstanceTypeGraph]: <NodeInstanceTypeGraph {...params} />,
    [Graph.NodeLifeCycleGraph]: <NodeLifeCycleGraph {...params} />,
    [Graph.NamespaceLimitationByMemoryGraph]: <NamespaceLimitationByMemoryGraph {...params} />,
    [Graph.NamespaceLimitationByCPUGraph]: <NamespaceLimitationByCPUGraph {...params} />,
    [Graph.NamespaceLimitationByPodsGraph]: <NamespaceLimitationByPodsGraph {...params} />,
    [Graph.NamespaceLimitationByReplicaSetsGraph]: <NamespaceLimitationByReplicaSetsGraph {...params} />,
    [Graph.ScaleOpsCPUUsage]: <ScaleOpsCPUUsage {...params} />,
    [Graph.ScaleOpsMemoryUsage]: <ScaleOpsMemoryUsage {...params} />,
    [Graph.ScaleOpsCPURequests]: <ScaleOpsCPURequests {...params} />,
    [Graph.ScaleOpsMemoryRequests]: <ScaleOpsMemoryRequests {...params} />,
  };

  return graphElements;
};
