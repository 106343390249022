import clsx from "clsx";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { ReactNode } from "react";
import { DEFAULT_DATE_TIME_FORMAT, roundedOrTwoDecimal } from "../../../../utils/formatterUtils";
import { EVENT_COLORS, EventName, EventPoint, EventType } from "./utils";

dayjs.extend(dayjsPluginUTC);

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    payload: EventPoint;
  }[];
  selectedEvents: EventType[];
  tooltipPayloadContext: EventPoint | undefined;
  displayNameStringTransformer: (key: string) => string;
  timeFormat?: string;
}

interface EventProps {
  name: string;
  value: ReactNode | null | undefined;
  color: string;
  comment?: ReactNode;
}

const Event = ({ name, value, color, comment }: EventProps) => {
  const displayValue = roundedOrTwoDecimal(value);

  return (
    <div className="flex flex-col">
      <div className="rounded-lg py-[0.5px] text-[10px] flex items-center gap-2" key={name}>
        <div
          style={{
            backgroundColor: color,
          }}
          className="h-1 w-8 rounded-full"
        />
        <div className="w-full flex justify-between">
          <div className="flex flex-col">{name}</div>
          <span className="pl-4">{displayValue}</span>
        </div>
      </div>
      <>{comment && <div className="text-[8px] ml-[34px] mr-4">{comment}</div>}</>
    </div>
  );
};

function getDisplayName(key?: string): string {
  if (!key) return ""; // Return an empty string if the key is undefined or null

  const normalizedKey = key.toLowerCase();

  switch (normalizedKey) {
    case "evctedbyscaleops".toLowerCase():
      return "Pod disruption (evicted by ScaleOps)";
    case "scaleddownhpa".toLowerCase():
      return "Pod disruption (scaled down by HPA)";
    case "preempt".toLowerCase():
      return "Pod disruption (preempted by kubelet)";
    case "kubeleteviction".toLowerCase():
      return "Pod disruption (kubelet eviction)";
    case "nodescaleddown".toLowerCase():
      return "Pod disruption (node scale down)";
    case "nodedown".toLowerCase():
      return "Pod disruption (node removed)";
    case "nodedownsuspected".toLowerCase():
      return "Pod disruption (node removed suspected)";
    case "unknown".toLowerCase():
      return "Pod disruption (pod removed)";
    default:
      return "Pod disruption (" + key + ")"; // Return the original key if no match is found
  }
}

const CustomTooltip = ({
  active,
  payload,
  selectedEvents,
  tooltipPayloadContext,
  timeFormat = DEFAULT_DATE_TIME_FORMAT,
  displayNameStringTransformer,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const payloadContent = tooltipPayloadContext ?? payload[0].payload;
    const evictionValue = payloadContent[EventType.EVICTION];
    const ommEventValue = payloadContent[EventType.OOM_EVENT];
    const oomKubeletValue = payloadContent[EventType.OOM_KUBELET];
    const oomLimitValue = payloadContent[EventType.OOM_LIMIT];
    const oomLimitByContainerValue = payloadContent[EventType.OOM_LIMIT_BY_CONTAINER];
    const oomNodeValue = payloadContent[EventType.OOM_NODE];
    const highUtilizationNodesValue = payloadContent[EventType.HIGH_UTILIZATION_NODES];
    const cpuThrottlingValue = payloadContent[EventType.CPU_THROTTLING];
    const autoHealingValue = payloadContent[EventType.AUTO_HEALING];
    const cpuFastReactionValue = payloadContent[EventType.CPU_FAST_REACTION];
    const memoryFastReactionValue = payloadContent[EventType.MEMORY_FAST_REACTION];
    const fastReactionValue = payloadContent[EventType.FAST_REACTION];
    const podDisruptionValue = payloadContent[EventType.POD_DISRUPTION];
    const podDisruptionReasonValue = payloadContent[EventType.POD_DISRUPTION_REASON];

    selectedEvents = selectedEvents.includes(EventType.OOM_LIMIT)
      ? [...selectedEvents, EventType.OOM_LIMIT_BY_CONTAINER]
      : selectedEvents;

    const events = [
      {
        type: EventType.OOM_EVENT,
        value: ommEventValue,
        color: EVENT_COLORS.oomEvent,
      },
      {
        type: EventType.CPU_FAST_REACTION,
        value: cpuFastReactionValue,
        color: EVENT_COLORS.cpuFastReaction,
      },
      {
        type: EventType.MEMORY_FAST_REACTION,
        value: memoryFastReactionValue,
        color: EVENT_COLORS.memoryFastReaction,
      },
      {
        type: EventType.OOM_KUBELET,
        value: oomKubeletValue,
        color: EVENT_COLORS.oomKubelet,
      },
      {
        type: EventType.OOM_LIMIT,
        value: oomLimitValue,
        color: EVENT_COLORS.oomLimit,
      },
      {
        type: EventType.OOM_LIMIT_BY_CONTAINER,
        value: oomLimitByContainerValue,
        color: EVENT_COLORS.oomLimit,
      },
      {
        type: EventType.OOM_NODE,
        value: oomNodeValue,
        color: EVENT_COLORS.oomNode,
      },
      {
        type: EventType.HIGH_UTILIZATION_NODES,
        value: highUtilizationNodesValue,
        color: EVENT_COLORS.highUtilizationNodes,
      },
      {
        type: EventType.CPU_THROTTLING,
        value: cpuThrottlingValue,
        color: EVENT_COLORS.cpuThrottling,
      },
      {
        type: EventType.EVICTION,
        value: evictionValue,
        color: EVENT_COLORS.eviction,
      },
      {
        type: EventType.AUTO_HEALING,
        value: autoHealingValue,
        color: EVENT_COLORS.autoHealing,
      },
      {
        type: EventType.HIGH_CPU_UTILIZATION,
        value: payloadContent[EventType.HIGH_CPU_UTILIZATION],
        color: EVENT_COLORS.highCpuUtilization,
      },
      {
        type: EventType.HIGH_MEMORY_UTILIZATION,
        value: payloadContent[EventType.HIGH_MEMORY_UTILIZATION],
        color: EVENT_COLORS.highMemoryUtilization,
      },
      {
        type: EventType.NODE_MEMORY_PRESSURE_EVENT,
        value: payloadContent[EventType.NODE_MEMORY_PRESSURE_EVENT],
        color: EVENT_COLORS.nodeMemoryPressureEvent,
      },
      {
        type: EventType.AUTO,
        value: payloadContent[EventType.AUTO],
        color: EVENT_COLORS.auto,
      },
      {
        type: EventType.IMAGE_CHANGE,
        value: payloadContent[EventType.IMAGE_CHANGE],
        color: EVENT_COLORS.imageChange,
      },
      {
        type: EventType.FAST_REACTION,
        value: fastReactionValue,
        color: EVENT_COLORS.fastReaction,
      },
      {
        type: EventType.POD_DISRUPTION,
        value: podDisruptionValue,
        color: EVENT_COLORS.podDisruption,
      },
      {
        type: EventType.POD_DISRUPTION_REASON,
        value: podDisruptionReasonValue,
        color: EVENT_COLORS.podDisruptionReason,
      },
    ];

    return (
      <div className="bg-[rgba(255,255,255,0.75)] text-black py-4 px-8 rounded-lg border border-border flex flex-col">
        <div className="font-bold text-[10px]">
          <p>{dayjs.unix(Number(payload[0].payload.timestamp)).format(timeFormat)}</p>
        </div>
        <div className="flex flex-col">
          {events.map((event) => {
            const isSelected = selectedEvents.includes(event.type);
            const eventCountMoreThanZero = event.value && Number(event.value) > 0;
            const isFastReaction = event.type === EventType.FAST_REACTION;
            const isOOMLimitByContainer = event.type === EventType.OOM_LIMIT_BY_CONTAINER;

            if (event.type === EventType.POD_DISRUPTION_REASON) {
              return null;
            }
            if (event.type === EventType.POD_DISRUPTION && isSelected && event.value) {
              const eventReason = events.filter((event) => event.type === EventType.POD_DISRUPTION_REASON);
              if (eventReason.length != 0) {
                return (
                  <Event
                    name={getDisplayName(eventReason[0].value as string)}
                    value={typeof event.value === "number" ? event.value : null}
                    color={event.color}
                  />
                );
              }
            }
            if (isOOMLimitByContainer && isSelected && event?.value) {
              const totalOomValue = Object.values(
                event?.value as {
                  [key: string]: number | undefined;
                }
              ).reduce((accumulator, currentValue) => (accumulator ?? 0) + (currentValue ?? 0), 0);
              const containers: string[] = Object.keys(event?.value as { [key: string]: number | undefined });
              const containersElement = (
                <span className={clsx("flex", { "flex-col": containers.length > 1 })}>
                  <div className="font-bold">{`Container${containers.length > 1 ? "s" : ""}: `}</div>
                  {containers.map((container) => (
                    <div key={container} className="ml-1">
                      {container}
                    </div>
                  ))}
                </span>
              );
              return (
                <Event
                  name={displayNameStringTransformer(EventName[EventType.OOM_LIMIT_BY_CONTAINER])}
                  comment={containersElement}
                  value={totalOomValue}
                  color={event.color}
                />
              );
            }

            if (isFastReaction && isSelected && eventCountMoreThanZero)
              return (
                <>
                  {cpuFastReactionValue && (
                    <Event
                      name={displayNameStringTransformer(EventName[EventType.CPU_FAST_REACTION])}
                      value={cpuFastReactionValue}
                      color={EVENT_COLORS.cpuFastReaction}
                    />
                  )}
                  {memoryFastReactionValue && (
                    <Event
                      name={displayNameStringTransformer(EventName[EventType.MEMORY_FAST_REACTION])}
                      value={memoryFastReactionValue}
                      color={EVENT_COLORS.memoryFastReaction}
                    />
                  )}
                </>
              );

            if (!isSelected || !eventCountMoreThanZero) return null;

            if (event.type === EventType.OOM_NODE) {
              return (
                <Event
                  name={"OOM node (container restart)"}
                  value={typeof event.value === "number" ? event.value : null}
                  color={event.color}
                />
              );
            }

            if (event.type === EventType.OOM_KUBELET) {
              return (
                <Event
                  name={"OOM node (pod restart)"}
                  value={typeof event.value === "number" ? event.value : null}
                  color={event.color}
                />
              );
            }

            return (
              <Event
                name={displayNameStringTransformer(EventName[event.type])}
                value={typeof event.value === "number" ? event.value : null}
                color={event.color}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
