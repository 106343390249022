import { Link, Typography } from "@mui/material";
import BackgroundPattern from "./BackgroundPattern";
import CodeSnippet, { THEME } from "./components/CodeSnippet";
import useGetVersion from "./pages/Cost/hooks/useGetVersion";
import { helmSetToString } from "./pages/Roles/Permission/commandsUtils";
import { GetConfig } from "./utils/ConfigHelper";
import { getCustomerPrefix } from "./utils/configUtils";
import { documentationUrl } from "./utils/urlUtils";

const getInstallCommand = (
  repoToDisplay: string,
  token: string,
  extraHelmSets: string,
  customerPrefix: string,
  hideToken: boolean
) => {
  if (hideToken) {
    token = token.replace(/./g, "*");
  }
  const installCommand = `
helm install --create-namespace -n scaleops-system \\
  --repo ${repoToDisplay} \\
  --username scaleops --password ${token} \\
  --set scaleopsToken=${token} \\
  --set clusterName=${customerPrefix}-$(kubectl config current-context)
`;
  return extraHelmSets ? `${installCommand} \\ ${extraHelmSets}` : installCommand;
};

const NoClustersPage = () => {
  const { data } = useGetVersion();
  const conf = GetConfig();
  const token = conf.token ?? "<SCALEOPS_TOKEN>";
  const repoToDisplay = data?.helmCommandConfig?.repositoryURL ?? "<SCALEOPS_REPOSITORY>";
  const extraHelmSets = helmSetToString(data?.helmCommandConfig?.extraHelmSets);

  const installCommand = getInstallCommand(repoToDisplay, token, extraHelmSets, getCustomerPrefix(conf), false);
  const installCommandWithHiddenToken = getInstallCommand(
    repoToDisplay,
    token,
    extraHelmSets,
    getCustomerPrefix(conf),
    true
  );

  return (
    <div className="bg-[#5a588a] w-full h-[100vh] absolute flex justify-center items-center z-50">
      <div
        style={{
          zIndex: -1,
        }}
      >
        <BackgroundPattern />
      </div>
      <div className="bg-[rgba(255,255,255,0.85)] w-fit h-fit p-16 border-2 rounded-lg border-white mt-[-10%]">
        <div className="flex flex-col gap-8 relative z-10">
          <Typography variant="h5" fontWeight={600} className="text-center flex justify-center items-center gap-2">
            Welcome to ScaleOps
          </Typography>
          <CodeSnippet
            description={
              <Typography variant="body1" className="text-center">
                Get started by installing ScaleOps on your Kubernetes cluster
              </Typography>
            }
            codeSnippetToCopy={installCommand}
            codeSnippet={installCommandWithHiddenToken}
            theme={THEME.light}
          />
        </div>
        <Typography variant="caption" className="text-center flex justify-center z-50">
          Or visit our&nbsp;
          <Link
            href={documentationUrl({
              token: conf.token,
            })}
            target="_blank"
            rel="noreferrer"
          >
            documentation
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default NoClustersPage;
