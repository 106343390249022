import { useFormikContext } from "formik";
import { components } from "../../../api/schema";
import FormSingleSelect, { RenderValueType } from "../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import * as policyUtils from "../../../utils/policyUtils";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { getDisabledByKeepRequestTooltipContent } from "./utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

const options: [string, number][] = [
  // ["70%", 70],
  // ["75%", 75],
  ["80%", 80],
  ["85%", 85],
  ["90%", 90],
  ["95%", 95],
  ["99%", 99],
  ["100% (MAX)", 100],
];

interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
  disableCPU?: boolean;
  disableMemory?: boolean;
}

const HistogramRequestPercentileConfiguration = ({
  isCustomizedPolicy,
  defaultPolicy,
  disableCPU,
  disableMemory,
}: Props) => {
  const defaultHistogramCPUPercentile = isCustomizedPolicy ? policyUtils.policyCPUPercentile(defaultPolicy) : undefined;

  const { values } = useFormikContext<{
    historyWindowCpu: string;
    historyWindowMemory: string;
  }>();

  const defaultHistogramMemoryPercentile = isCustomizedPolicy
    ? policyUtils.policyMemoryPercentile(defaultPolicy)
    : undefined;

  const currentHistoryWindowCpu30or90 = values.historyWindowCpu === "720h" || values.historyWindowCpu === "2160h";
  const currentHistoryWindowMemory30or90 =
    values.historyWindowMemory === "720h" || values.historyWindowMemory === "2160h";

  return (
    <div className="flex flex-col gap-4 pb-2">
      <FormTitleAndDescription
        title="Histogram request percentile"
        description="Define how close should ScaleOps be to the usage that measured."
      />
      <div className="flex flex-wrap gap-4">
        <FormSingleSelect
          label="CPU Request (%)"
          name="histogramCPUPercentile"
          options={options}
          areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
          defaultValue={defaultHistogramCPUPercentile}
          renderValueType={RenderValueType.Default}
          disabled={(!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || disableCPU}
          tooltipContent={disableCPU ? getDisabledByKeepRequestTooltipContent("CPU") : undefined}
          additionalOnChange={(value) => {
            if (value !== 100 && currentHistoryWindowCpu30or90) {
              values.historyWindowCpu = "24h";
            }
          }}
        />
        <FormSingleSelect
          label="Memory Request (%)"
          name="histogramMemoryPercentile"
          options={options}
          areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
          defaultValue={defaultHistogramMemoryPercentile}
          renderValueType={RenderValueType.Default}
          disabled={(!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || disableMemory}
          tooltipContent={disableMemory ? getDisabledByKeepRequestTooltipContent("memory") : undefined}
          additionalOnChange={(value) => {
            if (value !== 100 && currentHistoryWindowMemory30or90) {
              values.historyWindowMemory = "24h";
            }
          }}
        />
      </div>
    </div>
  );
};

export default HistogramRequestPercentileConfiguration;
