import clsx from "clsx";
import { useEffect, useState } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";
import DuplicateIcon from "../../Icons/DuplicateIcon";
import AccordionSection from "../../components/AccordionSection";
import Button from "../../components/Button";
import SelectDashboard from "../../components/Dashboard/SelectDashboard";
import MultiSelect from "../../components/MultiSelect";
import RangePicker, { RangeOptions } from "../../components/RangePicker/RangePicker";
import Tooltip from "../../components/Tooltip";
import VerticalSeparator from "../../components/VerticalSeparator";
import TopKFilters from "../Analytics/AnalyticsV2/Graphs/TopKFilters";
import NumberOfElementsFilter from "../Analytics/AnalyticsV2/NumberOfElementsFilter";
import SelectAggregationView from "../Analytics/AnalyticsV2/SelectAggregationView";
import { Graph, GraphDisplayName } from "./Graphs/utils";
import useGetGraphState from "./useGetGraphsState";
import { CustomDashboard, TROUBLESHOOT_DASHBOARD_CATEGORY } from "./utils";

const SHOW_NUMBER_OF_ELEMENTS_FILTER = false;

const DEFAULT_DASHBOARDS = Object.entries(CustomDashboard).map((option) => {
  return [option[0], option[1]];
});

const SaveButtonTooltipContent = {
  builtIn: <>Built-in dashboards cannot be modified or deleted.</>,
  customNotModified: <>Change the layout to enable the save button.</>,
  customModified: <>Save the current layout.</>,
};

interface Props {
  selectedElements: (string | undefined)[];
  setSelectedElements: (selectedElements: (string | undefined)[]) => void;
  saveDashboard: () => void;
  wasLayoutChanged: boolean;
  setIsDuplicateDialogueOpen: (isDuplicateDialogueOpen: boolean) => void;
  setIsCreateDialogueOpen: (isDuplicateDialogueOpen: boolean) => void;
  existingDashboardNames: string[];
  deleteDashboard: (dashboardName: string) => void;
  isReadOnly?: boolean;
  isMultiCluster?: boolean;
}

const TroubleshootTopSection = ({
  selectedElements,
  setSelectedElements,
  saveDashboard,
  wasLayoutChanged,
  setIsDuplicateDialogueOpen,
  setIsCreateDialogueOpen,
  existingDashboardNames,
  deleteDashboard,
  isReadOnly,
  isMultiCluster,
}: Props) => {
  const { date, setDate } = useGetGraphState();
  const [isSaveButtonActive, setIsSaveButtonActive] = useState<boolean>(false);
  const [saveButtonContent, setSaveButtonContent] = useState<React.ReactNode>(null);

  const [namespacesFilter] = useQueryParam("namespaces", ArrayParam);
  const [labelsFilter] = useQueryParam("labels", ArrayParam);
  const [annotationsFilter] = useQueryParam("annotations", ArrayParam);
  const [isFiltersOpenOnMount, setIsFiltersOpenOnMount] = useState<boolean>(false);

  useEffect(() => {
    setIsFiltersOpenOnMount(!!namespacesFilter || !!labelsFilter || !!annotationsFilter);
  }, [namespacesFilter, labelsFilter, annotationsFilter]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setIsSaveButtonActive(!isReadOnly && wasLayoutChanged);
    }, 300);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [wasLayoutChanged, isReadOnly]);

  useEffect(() => {
    switch (true) {
      case isReadOnly:
        setSaveButtonContent(SaveButtonTooltipContent.builtIn);
        break;
      case wasLayoutChanged:
        setSaveButtonContent(SaveButtonTooltipContent.customModified);
        break;
      default:
        setSaveButtonContent(SaveButtonTooltipContent.customNotModified);
        break;
    }
  }, [wasLayoutChanged, isReadOnly]);

  return (
    <div className="w-full rounded-lg bg-white px-8 py-5 flex flex-col">
      <div className="w-full rounded-lg flex gap-4">
        <div className="w-full flex gap-4 items-center">
          <SelectDashboard
            setIsDuplicateDialogueOpen={setIsDuplicateDialogueOpen}
            setIsCreateDialogueOpen={setIsCreateDialogueOpen}
            existingDashboardNames={existingDashboardNames}
            deleteDashboard={deleteDashboard}
            defaultDashboards={DEFAULT_DASHBOARDS}
            defaultDashboard={CustomDashboard.Performance}
            dashBoardCategory={TROUBLESHOOT_DASHBOARD_CATEGORY}
          />
          <MultiSelect
            label="Charts"
            selected={selectedElements}
            setSelected={setSelectedElements}
            categoryOptions={[
              {
                categoryName: "Optimization",
                options: [Graph.AutomationEventsGraph, Graph.ScaleOpsOptimizationEvictionsGraph],
              },
              {
                categoryName: "Performance",
                options: [
                  Graph.CPUUnderProvisionedOnStressedNodesGraph,
                  Graph.MemoryUnderProvisionedOnStressedNodesGraph,
                  Graph.CPUUnderProvisionedGraph,
                  Graph.MemoryUnderProvisionedGraph,
                  Graph.WorkloadDisruptionsGraph,
                  Graph.DowntimeEventsGraph,
                  Graph.OutOfMemoryEventsGraph,
                  Graph.CPUThrottlingEventsGraph,
                  Graph.LivenessProbeFailuresGraph,
                ],
              },
              {
                categoryName: "Cost",
                options: [
                  Graph.WastefulGraph,
                  Graph.ExpensiveGraph,
                  Graph.SmartPolicyWasteGraph,
                  Graph.CustomWorkloadsUnidentifiedGraph,
                  Graph.WastedCPUGraph,
                  Graph.WastedMemoryGraph,
                ],
              },
              {
                categoryName: "Nodes",
                options: [
                  Graph.NodeCpuUtilizationGraph,
                  Graph.NodeMemoryUtilizationGraph,
                  Graph.NodeCPUAllocationGraph,
                  Graph.NodeMemoryAllocationGraph,
                  Graph.NodeNotScalingDownGraph,
                  Graph.NodeLifeCycleGraph,
                  Graph.NodeInstanceTypeGraph,
                ],
              },
              {
                categoryName: "ScaleOps workloads",
                options: [
                  Graph.VersionGraph,
                  Graph.ScaleOpsCPUUsage,
                  Graph.ScaleOpsMemoryUsage,
                  Graph.ScaleOpsCPURequests,
                  Graph.ScaleOpsMemoryRequests,
                  Graph.IssuesGraph,
                ],
              },
              {
                categoryName: "Resource quotas",
                options: [
                  Graph.NamespaceLimitationByCPUGraph,
                  Graph.NamespaceLimitationByMemoryGraph,
                  Graph.NamespaceLimitationByPodsGraph,
                  Graph.NamespaceLimitationByReplicaSetsGraph,
                ],
              },
            ]}
            modifyOptionText={(option) => GraphDisplayName[option as keyof typeof GraphDisplayName]}
            width={200}
            className="h-[2.5rem]"
            showNumberOfSelectedValue
            hasSelectAllAndClearSelection={false}
            isSearchable={true}
          />
          <VerticalSeparator />
          {SHOW_NUMBER_OF_ELEMENTS_FILTER && <NumberOfElementsFilter />}
          {!isMultiCluster && <SelectAggregationView hasLabel />}
        </div>
        <div className="flex items-center gap-4">
          <VerticalSeparator />
          <Tooltip title={saveButtonContent} disabled={!isSaveButtonActive} className="relative">
            <Button
              onClick={saveDashboard}
              label="Save"
              disabled={!isSaveButtonActive}
              className={clsx({ "animate-bounce": wasLayoutChanged && isSaveButtonActive })}
              style={{
                animationIterationCount: 3,
              }}
            />
          </Tooltip>
          <Tooltip title="Duplicate dashboard">
            <DuplicateIcon
              onClick={() => {
                setIsDuplicateDialogueOpen(true);
              }}
              width={24}
              height={24}
              className="cursor-pointer"
            />
          </Tooltip>
          <VerticalSeparator />
          <div>
            <RangePicker date={date} setDate={setDate} rangeOptions={RangeOptions.SHORT} minDateRangeInDays={14} />
          </div>
        </div>
      </div>
      <AccordionSection
        title="Filters"
        titleVariant="caption"
        className="py-2 mb-[-20px] px-[0px]"
        childrenWrapperClassName="mt-[0.25rem]"
        openOnMount={isFiltersOpenOnMount}
      >
        <TopKFilters isMultiCluster={isMultiCluster} />
      </AccordionSection>
    </div>
  );
};

export default TroubleshootTopSection;
