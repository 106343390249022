import { Alert, AlertProps, Snackbar, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { getMultiClusterSettings, GetMultiClusterSettingsResponse } from "../../api/fetcher";
import { components } from "../../api/schema";
import { SCALEOPS_COLORS } from "../../colors";
import { GetConfig } from "../../utils/ConfigHelper";
import { documentationUrl } from "../../utils/urlUtils";
import Button from "../Button";
import CodeSnippet, { THEME as CodeSnippetTheme } from "../CodeSnippet";
import Dialog from "../Dialog";
import Input from "../Input";
import MultiClusterFilters from "../multiClusterFilters";
import { helmSetup } from "../sso";
import Tooltip from "../Tooltip";

interface ConnectNewChildProps {
  open: boolean;
  close: () => void;
  handleProcessRowUpdateSuccess: (message: string) => void;
  handleProcessRowUpdateError: (message: string) => void;
}

function ConnectNewChild({ open, close }: ConnectNewChildProps): JSX.Element {
  const conf: components["schemas"]["ConfGetConfResponse"] = GetConfig();
  const [ssoConf, setSsoConf] = useState<components["schemas"]["TypesAuthInfo"]>({ enabled: false, provider: "none" });

  useEffect(() => {
    if (!conf.ssoConf && ssoConf.provider === "none") {
      setSsoConf({
        enabled: false,
        provider: "none",
      });
    }
    if (conf.ssoConf && JSON.stringify(conf.ssoConf) !== JSON.stringify(ssoConf)) {
      setSsoConf(conf.ssoConf);
    }
  }, [conf]);

  return (
    <Dialog
      isOpen={open}
      onClose={close}
      title="Add Cluster"
      dialogContentStyle={{
        padding: "0px 20px 20px 20px",
      }}
    >
      <div className="w-full flex flex-col gap-4 items-end">
        <CodeSnippet
          description={<br />}
          theme={CodeSnippetTheme.light}
          codeSnippet={helmSetup({
            conf,
            showToken: false,
          })}
          codeSnippetToCopy={helmSetup({
            conf,
            showToken: true,
          })}
          className="w-full"
        />
        <div className="w-full">
          <DocsLink ssoConf={ssoConf} />
        </div>
        <Button onClick={close} label="Done" />
      </div>
    </Dialog>
  );
}

function QuickSearchToolbar() {
  const [searchTerm, setSearchTerm] = useQueryParam("searchTerm", StringParam);
  return (
    <div className="flex items-center">
      <Input
        className="min-w-[150px]"
        borderColor={SCALEOPS_COLORS.black}
        placeholder="search..."
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        value={searchTerm ?? ""}
      />
    </div>
  );
}

interface Props {
  uniqueClusterTags: string[];
}

export default function EditToolbarMultiCluster({ uniqueClusterTags }: Props) {
  const [connectAChildCluster, setConnectAChildCluster] = useState<boolean>(false);
  const [openDialogueOnLoad, setOpenDialogueOnLoad] = useQueryParam("openDialogueOnLoad", BooleanParam);

  useEffect(() => {
    if (!connectAChildCluster && openDialogueOnLoad) {
      setTimeout(() => {
        setOpenDialogueOnLoad(false);
        setConnectAChildCluster(true);
      }, 1000);
    }
  }, [openDialogueOnLoad, connectAChildCluster]);

  const [snackbar, setSnackbar] = useState<Pick<AlertProps, "children" | "severity"> | null>(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const handleProcessRowUpdateError = useCallback((message: string) => {
    setSnackbar({ children: message, severity: "error" });
  }, []);

  const handleProcessRowUpdateSuccess = useCallback((message: string) => {
    setSnackbar({ children: message, severity: "success" });
  }, []);

  const conf = GetConfig();

  const multiClusterSettings = getMultiClusterSettings();
  const { data } = useQuery<GetMultiClusterSettingsResponse, Error>({
    queryKey: [multiClusterSettings.queryKey],
    queryFn: multiClusterSettings.queryFn,
    refetchInterval: 10 * 1000,
  });
  const isChildCluster = (): boolean => {
    return data?.settings?.host !== undefined && data.settings.host.length > 0;
  };
  const parentUrl = (): string => {
    if (data !== undefined) {
      return data.settings?.host ?? "";
    }
    return "";
  };

  return (
    <div className="pb-8">
      <div className="flex gap-2">
        <QuickSearchToolbar />
        <MultiClusterFilters tagOptions={uniqueClusterTags} />
        <div className="grow items-center flex justify-end">
          {!conf.inCloudMode ? (
            <Tooltip
              title={
                <>
                  Cluster is connected to Parent at{" "}
                  <a target="_blank" href={parentUrl()}>
                    {parentUrl()}
                  </a>
                </>
              }
              disabled={!isChildCluster()}
            >
              <Button
                disabled={isChildCluster()}
                label="Add cluster"
                className="animate-bounce temporary-bounce"
                dataTestId="cluster-connection-details-button"
                onClick={() => {
                  setConnectAChildCluster(true);
                }}
                variant="smallGreen"
              />
            </Tooltip>
          ) : null}
        </div>
      </div>

      <ConnectNewChild
        open={connectAChildCluster}
        close={() => setConnectAChildCluster(false)}
        handleProcessRowUpdateError={handleProcessRowUpdateError}
        handleProcessRowUpdateSuccess={handleProcessRowUpdateSuccess}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}

export const DocsLink = (props: { ssoConf: components["schemas"]["TypesAuthInfo"] | undefined }): JSX.Element => {
  const { ssoConf } = props;
  const conf: components["schemas"]["ConfGetConfResponse"] = GetConfig();
  const multiClusterGuideUrl = documentationUrl({
    token: conf.token,
    path: "installation/multi-cluster/",
  });

  const ssoSection = (
    <Typography variant="caption">
      For more information <b>please check out our docs</b>:
      <ul className="list-disc pl-3">
        <li>
          <a
            target="_blank"
            className="cursor-pointer text-main-linkBlue hover:text-guideline-darkBlue hover:underline"
            href={multiClusterGuideUrl}
            style={{
              fontWeight: 600,
            }}
          >
            Multi Cluster Setup
          </a>
        </li>
      </ul>
    </Typography>
  );

  if (ssoConf?.enabled) {
    return ssoSection;
  } else {
    return (
      <Typography variant="caption">
        For more information <b>please check out our docs</b>:
        <ul className="list-disc pl-3">
          <li>
            <a
              className="cursor-pointer text-main-linkBlue hover:text-guideline-darkBlue hover:underline"
              href={multiClusterGuideUrl}
            >
              Multi Cluster Setup
            </a>
          </li>
        </ul>
      </Typography>
    );
  }
};
